<template>
    <div class="preview_area" v-if="modelValue">
        <div class="global_setting">
            <button class="close_btn modal-window-close-button" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
            <div class="tabs_content">
                <Form v-slot="{ errors }" @invalid-submit="handleFormInvalidSubmit" @submit="handleSubmitForm" ref="canva-playbook-form" class="canva_playbook_form">
                    <div class="content_area">
                        <div class="info_bar">
                            <div class="step_bar2">
                                <ul>
                                    <li :class="{ active : step == 0 || step > 0 }">
                                        <span @click="playbookId ? '' : step = 0">1</span>
                                        <h6>Upload Your Zip</h6>
                                    </li>
                                    <li :class="{ active : step == 1 || step > 1 }">
                                        <span @click="step = 1">2</span>
                                        <h6>Add Details</h6>
                                    </li>
                                    <li :class="{ active : step == 2 || step > 2 }">
                                        <span @click="step = 2">3</span>
                                        <h6>Finalize Layout</h6>
                                    </li>
                                </ul>
                                <div class="total_bar"><span :style="`width:${(100 / 2) * step }%`"></span></div>
                            </div>
                            <div class="btn_wpr">
                                <div class="tutorial_btn" @click="tutorialList = !tutorialList;" v-click-outside="closeTutorialListDropdown">
                                    <label>Tutorials</label><img src="@/assets/images/play.svg">
                                    <div class="dropdown_wpr" :class="tutorialList ? 'active' : ''">
                                        <ul>
                                            <li @click="handleTutorialVideo()">How to create a playbook from Canva in 5 minutes or less.</li>
                                        </ul>
                                    </div>
                                </div>
                                <button type="button" class="action_btn" @click="showArchive = true;">See Deleted</button>
                            </div>
                        </div>
                        <div class="setting_wpr wrapper_box" v-if="step == 0">
                            <div class="upload_zip mb-1">
                                <label v-if="canvaZipFileUploadLoaderStatus" class="file-upload-loader">
                                    <div class="spinner"></div>
                                    <h4>Your file may take a few seconds to upload. <br> The wizard will progress to step 2 when it completes.</h4>
                                </label>
                                <label for="show_email" v-else>
                                    <input type="file" v-bind="getInputProps({ ref: 'canva-import-file', multiple: false })" @change="fileSelected($event)" ref="canva-import-file" id="show_email" accept="application/zip" hidden>
                                    <img src="@/assets/images/upload.svg" class="icon">
                                    <h4>Click to Import <img src="@/assets/images/canva.png"> Zip File</h4>
                                </label>
                            </div>
                            <ErrorMessage name="file" class="text-danger" />
                            <div class="note">
                                <h4>Arrange your Canva design in the desired display order and export it as an 8.5" x 11" PNG Zip file. Once done, upload the Zip file here.</h4>
                            </div>
                        </div>
                        <div class="setting_wpr wrapper_box" v-if="step == 1">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Playbook Name</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.title }">
                                        <Field autocomplete="off" type="text" name="title" v-model="form.title" placeholder="ex: New Playbook" rules="required" />
                                    </div>
                                    <ErrorMessage name="title" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Playbook Categories <a class="option" @click="createCategory = !createCategory">Create Smart Category</a></label>
                                        <div class="field_wpr">
                                        <multiselect
                                            v-model="form.categories"
                                            :options="playbookCategories"
                                            :searchable="true"
                                            valueProp="id"
                                            label="name"
                                            placeholder="Select Category"
                                            mode="tags"
                                        ></multiselect>
                                    </div>
                                </div>
                            </div>
                            <div v-if="createCategory" class="form_grp mb-2">
                                <div class="group_item">
                                    <label class="input_label">Category Name</label>
                                    <Form @submit="handleCreateCategory" v-slot="{ errors }" ref="category-form">
                                        <div class="field_wpr has_suffix" :class="{ 'has-error': errors.name }">
                                            <Field autocomplete="off" type="text" name="name" v-model="categoryForm.name" placeholder="Enter Category Name" rules="required" />
                                            <span class="suffix" @click="handleCreateCategory()"><i class="fa fa-spin fa-spinner" v-if="categorySaveLoader"></i> {{ categorySaveLoader ? 'Creating' : 'Create' }}</span>
                                        </div>
                                        <ErrorMessage name="name" class="text-danger" />
                                    </Form>
                                </div>
                            </div>
                            <div class="form_grp mb-2">
                                <div class="group_item">
                                    <label class="input_label">Playbook Description</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.description }">
                                        <Field name="description" v-model="form.description" label="description" autocomplete="off">
                                            <textarea name="description" autocomplete="off" cols="30" rows="10" placeholder="Description goes here.." v-model="form.description"></textarea>
                                        </Field>
                                    </div>
                                    <ErrorMessage name="description" class="text-danger" />
                                </div>
                            </div>
                            <label for="saved_styling" class="switch_option capsule_btn border-0">
                                <h5 class="large">Use Saved Styling?</h5>
                                <input type="checkbox" id="saved_styling" v-model="form.use_default" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div class="wrapper_box draggable_box" v-if="step == 2">
                            <draggable v-model="canvaFiles" tag="ul" item-key="id" class="canva_list" :move="handleSlotMove" @end="handleSortSteps" ghost-class="ghost" :animation="200" handle=".handle_slot">
                                <template #item="{element, index}">
                                    <li @click.stop="selectedSlot = index;" :class="selectedSlot == index ? `active slot-${index}` : `slot-${index}`">
                                        <div class="slot_wpr">
                                            <div class="slot_head">
                                                <label v-tooltip="'Sort without grouping'" position="right">
                                                    <i class="fas fa-arrows-alt handle_slot"></i>
                                                </label>
                                                <span v-if="element.items.length > 1" v-tooltip="'Customize group'" @click="slotDetails = true;">{{element.items.length}} pages</span>
                                                <div class="delete_btn" @click="handleDelete(element.id, true)" v-tooltip="`Remove`"><img src="@/assets/images/trash.svg"></div>
                                            </div>
                                            <div class="base_size">
                                                <draggable v-model="element.items" class="slot_items" item-key="id" :move="handleItemMove" @end="handleSortSteps" :animation="200" group="tasks" :class="{'blank': !element.items.length}">
                                                    <template #item="{element: item, index: i}">
                                                        <div class="book_item" :key="i">
                                                            <div class="options" @click.stop="toggleOption($event, index)" v-click-outside="closeAllToggleOption" v-if="element.items.length > 1">
                                                                <i class="fas fa-ellipsis-h"></i>
                                                                <div class="dropdown_wpr">
                                                                    <ul>
                                                                        <li  v-if="item.parent_id" @click="handleDegroup(item.id)">Ungroup</li>
                                                                        <li class="danger" @click="handleDelete(item.id, false)">Delete</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div class="page_title" style="display: none;">
                                                                <h4 @click="renameTitle($event, 'book_item', item)">
                                                                    <div class="title" v-tooltip="item.title ? item.title : ''" position="bottom-right"><label>{{item.title ? item.title : 'Add page title'}}</label></div>
                                                                </h4>
                                                                <div class="rename_fld">
                                                                    <Field autocomplete="nofill" type="text" :name="`item-title-${item.id}-${i}`" v-model="item.title" placeholder="ex: New Title" label="title" />
                                                                    <ErrorMessage :name="`item-title-${item.id}-${i}`" class="text-danger" />
                                                                    <button type="button" class="save_btn" @click="renameTitle($event, 'book_item', item)">Save</button>
                                                                </div>
                                                            </div>
                                                            <div class="page_wpr">
                                                                <img :src="item.img">
                                                            </div>
                                                        </div>
                                                    </template>
                                                </draggable>
                                            </div>
                                        </div>
                                        <div class="sl_no" :class="{ 'has-error': errors[`step_name_${element.id}`] }">
                                            <label>{{index + 1}}.</label>
                                            <h4 @click="renameTitle($event, 'sl_no', element)">{{ element.name ? element.name : "Add step name"}}</h4>
                                            <div class="rename_fld" :class="{ 'has-error': errors[`step_name_${element.id}`] }">
                                                <Field autocomplete="nofill" type="text" :name="`step_name_${element.id}`" v-model="element.name" placeholder="ex: New Step Name" label="step name" @focusout="renameTitle($event, 'sl_no', element)" />
                                                <button type="button" class="save_btn" @click="renameTitle($event, 'sl_no', element)">Save</button>
                                            </div>
                                        </div>
                                        <div class="sl_no m-0 p-0">
                                            <ErrorMessage :name="`step_name_${element.id}`" class="text-danger" />
                                        </div>
                                        <span class="add_slot" @click.stop="addNewSlot(element.order)" v-tooltip="'Add Space'"><i class="fas fa-plus"></i></span>
                                    </li>
                                </template>
                            </draggable>
                        </div>
                    </div>
                    <div class="action_wpr bg-light">
                        <button type="button" class="btn cancel_btn mr-auto ml-0 bg-light" @click="handlePrevStep()" v-if="step > 0">Back</button>
                        <button type="button" class="btn cancel_btn bg-light" @click="closeModal()">Cancel</button>
                        <button type="button" class="btn save_btn" @click="handleNextStep()" v-if="step == 0" :disabled="canvaZipFileUploadLoaderStatus">Next</button>
                        <button class="btn save_btn" v-if="step == 1" :disabled="playbookUserLoader"><i v-if="playbookUserLoader" class="fa fa-spin fa-spinner"></i> {{ playbookUserLoader ? 'Saving' : 'Next' }}</button>
                        <button type="button" class="btn save_btn" :disabled="canvaBuildLoaderStatus" v-if="step == 2" @click="handleBuildPlaybook()"><i v-if="canvaBuildLoaderStatus" class="fa fa-spin fa-spinner"></i> {{ canvaBuildLoaderStatus ? 'Building' : 'Build My Playbook' }}</button>
                    </div>
                </Form>
            </div>
        </div>
        <div class="modal slot_modal" v-if="slotDetails && selectedSlot !== null" :class="{ active : slotDetails }">
            <div class="modal_container">
                <div class="modal_header border-bottom">
                    <div class="modal_info">
                        <h1>{{selectedSlot + 1}}. {{canvaFiles[selectedSlot].name !== '' ? canvaFiles[selectedSlot].name : 'Group name'}}</h1>
                    </div>
                    <button class="close_btn left_out" @click="closeDrawer()"><i class="fas fa-long-arrow-alt-right"></i></button>
                </div>
                <div class="modal_body">
                    <ul class="canva_list">
                        <li>
                            <draggable v-model="canvaFiles[selectedSlot].items" item-key="id" class="slot_items" :animation="200" group="tasks">
                                <template #item="{element: item, index: i}">
                                    <div class="book_item" @click="selectedSlotItem = i" :class="{'active' : selectedSlotItem === i}">
                                        <div class="expand_btn" @click.stop="toggleExtract($event)"><img src="@/assets/images/expand-alt.svg"></div>
                                        <div class="options" @click.stop="toggleOption($event, selectedSlot)" v-click-outside="closeAllToggleOption">
                                            <i class="fas fa-ellipsis-h"></i>
                                            <div class="dropdown_wpr">
                                                <ul>
                                                    <li v-if="item.parent_id" @click="handleDegroup(item.id)">Ungroup</li>
                                                    <li class="danger" @click="handleDelete(item.id, false)">Delete</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="page_title" style="display: none;">
                                            <h4 @click="renameTitle($event, 'book_item', item)">
                                                <div class="title" v-tooltip="item.title ? item.title : ''" position="bottom-right"><label>{{item.title}}</label></div>
                                            </h4>
                                            <div class="rename_fld">
                                                <Field autocomplete="nofill" type="text" :name="`item_title_${item.id}`" v-model="item.title" placeholder="ex: New Title" label="title" />
                                                <ErrorMessage :name="`item_title_${item.id}`" class="text-danger" />
                                                <button type="button" class="save_btn" @click="renameTitle($event, 'book_item', item)">Save</button>
                                            </div>
                                        </div>
                                        <div class="rearrange">
                                            <span @click="handleArrange(item, i, 'neg')" :class="{'disabled' : i === 0}"><i class="fas fa-chevron-up"></i></span>
                                            <span @click="handleArrange(item, i, 'pos')" :class="{'disabled' : i === (canvaFiles[selectedSlot].items.length - 1)}"><i class="fas fa-chevron-down"></i></span>
                                        </div>
                                        <div class="page_wpr">
                                            <img :src="item.img">
                                        </div>
                                    </div>
                                </template>
                            </draggable>
                        </li>
                    </ul>
                </div>
                <div class="modal_footer">
                    <button type="button" class="btn cancel_btn" @click="closeDrawer()">Close</button>
                </div>
            </div>
        </div>
        <div class="modal slot_modal archive_drawer" v-if="showArchive" :class="{ active : showArchive }">
            <div class="modal_container">
                <div class="modal_header border-bottom">
                    <div class="modal_info">
                        <h1>Archive</h1>
                    </div>
                    <button class="close_btn left_out" @click="closeDrawer()"><i class="fas fa-long-arrow-alt-right"></i></button>
                </div>
                <div class="modal_body">
                    <div class="canva_list_loader" v-if="canvaArchivedStepsLoaderStatus">
                        <quote-loader />
                    </div>
                    <template v-else>
                        <ul class="canva_list m-0" v-if="canvaArchivedSteps.length">
                            <li v-for="(archive, a) of canvaArchivedSteps" :key="a">
                                <div class="slot_head">
                                    <span v-if="archive.items.length > 1">{{archive.items.length}} pages</span>
                                    <div class="options" @click.stop="toggleOption($event, selectedSlot)" v-click-outside="closeAllToggleOption">
                                        <i class="fas fa-ellipsis-h"></i>
                                        <div class="dropdown_wpr">
                                            <ul>
                                                <li @click="handleRestore(archive.id)">Move Back</li>
                                                <li class="danger" @click="handleDeleteConfirmation(archive.id)">Delete</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="slot_items">
                                    <div class="book_item" v-for="(page, p) in archive.items" :key="p">
                                        <div class="expand_btn" @click.stop="toggleExtract($event, 'slot_items')"><img src="@/assets/images/expand-alt.svg"></div>
                                        <div class="page_title" style="display: none;">
                                            <h4 @click="renameTitle($event, 'book_item', page)">
                                                <div class="title" v-tooltip="page.title ? page.title : ''" position="bottom-right"><label>{{page.title}}</label></div>
                                            </h4>
                                            <div class="rename_fld">
                                                <Field autocomplete="nofill" type="text" :name="`page_title_${page.id}`" v-model="page.title" placeholder="ex: New Title" label="title" />
                                                <ErrorMessage :name="`page_title_${page.id}`" class="text-danger" />
                                                <button type="button" class="save_btn" @click="renameTitle($event, 'book_item', page)">Save</button>
                                            </div>
                                        </div>
                                        <div class="page_wpr">
                                            <img :src="page.img">
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="empty_box" v-else>
                            <img src="@/assets/images/empty_state.svg" alt="Empty">
                            <h4 class="text-center">No Deleted items Found.</h4>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="modal secondary prompt" v-if="deleteConfirmationPrompt">
            <div class="modal_container">
                <img src="@/assets/images/alert.svg" alt="" class="logo_img">
                <h5>This action is pernmanent. Would you rather archive this message?</h5>
                <div class="action_wpr mt-3">
                    <button type="button" class="btn cancel_btn" :disabled="forceDeleteLoaderStatus" @click="deleteConfirmationPrompt = false;">Yes</button>
                    <button type="button" class="btn save_btn" :disabled="forceDeleteLoaderStatus" @click="handleForceDeleteCanvaStep(deleteCanvaStepId)"><i v-if="forceDeleteLoaderStatus" class="fa fa-spin fa-spinner"></i> {{ forceDeleteLoaderStatus ? 'Deleting' : 'No, Delete it' }}</button>
                </div>
            </div>
        </div>
        <div class="modal secondary prompt" v-if="emptyStepConfirmationPrompt">
            <div class="modal_container">
                <img src="@/assets/images/alert.svg" alt="" class="logo_img">
                <h5>Please remove all blank spaces and ensure your steps are properly labeled before publishing</h5>
                <div class="action_wpr mt-3">
                    <button type="button" class="btn cancel_btn" :disabled="canvaBuildLoaderStatus" @click="emptyStepConfirmationPrompt = false;">Ok</button>
                    <button type="button" class="btn save_btn" :disabled="canvaBuildLoaderStatus" @click="buildPlaybook()"><i v-if="canvaBuildLoaderStatus" class="fa fa-spin fa-spinner"></i> {{ canvaBuildLoaderStatus ? 'Building' : 'Build it' }}</button>
                </div>
            </div>
        </div>
        <video-tutorial v-model="tutorial" :tutorial-video="tutorialVideo"></video-tutorial>
    </div>
</template>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate'
import { mapState, mapActions } from 'vuex'
import { useDropzone } from 'vue3-dropzone'

import Draggable from 'vuedraggable';
import Toastr from '@/utils/Toastr'

export default {
    name: 'Canva Playbook Setup',

    data() {
        return {
            step: 0,
            form: {
                title: '',
                is_thumbnail: 0,
                use_default: 0,
                dashboard_thumb: '',
                description: '',
                categories: [],
                playbook_id: 0,
                batch: '',
            },
            createCategory: false,
            categoryForm: {
                name: '',
            },
            file: '',
            getInputProps: null,
            canvaFiles: [{
                name: '',
                items: []
            }],
            selectedSlot: null,
            slotDetails: false,
            selectedSlotItem: null,
            tutorialList: false,
            tutorial: false,
            tutorialVideo: '',
            showArchive: false,
            deleteConfirmationPrompt: false,
            activePlaybook: {},
            deleteCanvaStepId: 0,
            forceDeleteLoaderStatus: false,
            emptyStepConfirmationPrompt: false,
        }
    },

    props: {
        modelValue: Boolean,
        hasCanvaSetup: {
            type: Boolean,
            default: false,
        },
        playbookId: {
            type: Number,
            default: 0,
        },
        activeStep: {
            type: Number,
            default: 0
        }
    },

    emits: ['update:modelValue'],

    components: {
        Field,
        Form,
        ErrorMessage,
        Draggable,
    },

    watch: {
        modelValue (val) {
            const vm = this;

            vm.step = vm.activeStep;

            vm.resetForm();

            if (val) {
                vm.getPlaybookCategories();

                if (vm.playbookId) {
                    vm.getPlaybookCanvaSteps(vm.playbookId).then((result) => {
                        if (result) {
                            vm.form.title       = vm.canvaZipFiles.playbook.name;
                            vm.form.batch       = vm.canvaZipFiles.playbook.batch;
                            vm.form.title       = vm.canvaZipFiles.playbook.title;
                            vm.form.description = vm.canvaZipFiles.playbook.description;
                            vm.form.playbook_id = vm.canvaZipFiles.playbook.id;
                            vm.form.categories  = vm.canvaZipFiles.playbook.categories.map(c => c.category_id);
    
                            vm.canvaFiles       = JSON.parse(JSON.stringify(vm.canvaZipFiles.steps));
                        }
                    });
                }

                document.body.classList.add('modal-open');
            } else {
                document.body.classList.remove('modal-open');
            }
        },

        showArchive (val) {
            const vm = this;

            if (val) {
                vm.getArchivedPlaybookCanvaSteps({ playbook_id: vm.form.playbook_id, batch: vm.form.batch });
            }
        },
    },

    mounted () {
        const vm = this;

        const { getRootProps, getInputProps } = useDropzone({ onDrop: vm.dropzoneDrop });

        vm.getRootProps = getRootProps;
        vm.getInputProps = getInputProps;

        if (vm.hasCanvaSetup) {
            vm.step = 2;
        }
    },

    computed: mapState({
        playbookUserLoader: state => state.playbookModule.playbookUserLoader,
        categorySaveLoader: state => state.playbookModule.categorySaveLoader,
        playbookCategories: state => state.playbookModule.playbookCategories,
        canvaZipFiles: state => state.playbookModule.canvaZipFiles,
        canvaZipFileLoaderStatus: state => state.playbookModule.canvaZipFileLoaderStatus,
        canvaZipFileUploadLoaderStatus: state => state.playbookModule.canvaZipFileUploadLoaderStatus,
        canvaBuildLoaderStatus: state => state.playbookModule.canvaBuildLoaderStatus,
        canvaArchivedSteps: state => state.playbookModule.canvaArchivedSteps,
        canvaArchivedStepsLoaderStatus: state => state.playbookModule.canvaArchivedStepsLoaderStatus,
    }),

    methods: {
        ...mapActions({
            createPlaybook: 'playbookModule/createPlaybook',
            getPlaybookCategories: 'playbookModule/getPlaybookCategories',
            createPlaybookCategory: 'playbookModule/createPlaybookCategory',
            uploadPlaybookCanvaZip: 'playbookModule/uploadPlaybookCanvaZip',
            createPlaybookFromCanva: 'playbookModule/createPlaybookFromCanva',
            getPlaybookCanvaSteps: 'playbookModule/getPlaybookCanvaSteps',
            buildPlaybookFromCanva: 'playbookModule/buildPlaybookFromCanva',
            sortCanvaPlaybookSteps: 'playbookModule/sortCanvaPlaybookSteps',
            archivePlaybookCanvaSteps: 'playbookModule/archivePlaybookCanvaSteps',
            getArchivedPlaybookCanvaSteps: 'playbookModule/getArchivedPlaybookCanvaSteps',
            deletePlaybookCanvaStep: 'playbookModule/deletePlaybookCanvaStep',
            degroupPlaybookCanvaStep: 'playbookModule/degroupPlaybookCanvaStep',
            updatePlaybookCanvaStepTitle: 'playbookModule/updatePlaybookCanvaStepTitle',
            renamePlaybookCanvaStep: 'playbookModule/renamePlaybookCanvaStep',
            createPlaybookCanvaBlankStep: 'playbookModule/createPlaybookCanvaBlankStep',
            restorePlaybookCanvaStep: 'playbookModule/restorePlaybookCanvaStep',
        }),

        closeModal () {
            const vm = this;

            vm.$emit('update:modelValue', false);
        },

        resetForm () {
            const vm = this;

            vm.form = {
                title: '',
                is_thumbnail: 0,
                use_default: 0,
                dashboard_thumb: '',
                description: '',
                categories: [],
                playbook_id: 0,
                batch: '',
            };

            vm.canvaFiles = [{ name: '', items: [] }];
            vm.emptyStepConfirmationPrompt = false;
            vm.deleteConfirmationPrompt = false;
        },

        handleSubmitForm (form, { setFieldError }) {
            const vm = this;

            if (vm.step == 1) {
                vm.form.setFieldError = setFieldError;
                vm.createPlaybookFromCanva(vm.form).then((result) => {
                    if (result) {
                        vm.getPlaybookCanvaSteps({ playbook_id: vm.form.playbook_id, batch: vm.form.batch }).then((result) => {
                            if (result) {
                                vm.canvaFiles = JSON.parse(JSON.stringify(vm.canvaZipFiles.steps));
                            }
                        });

                        if (vm.step < 2) {
                            vm.step++;
                        }
                    }

                    // if (playbook.id) {
                    //     vm.activePlaybook = playbook;

                    //     vm.getPlaybookCanvaSteps(playbook.id).then((result) => {
                    //         if (result) {
                    //             vm.canvaFiles = JSON.parse(JSON.stringify(vm.canvaZipFiles.steps));
                    //         }
                    //     });

                    //     vm.form.title       = playbook.title;
                    //     vm.form.description = playbook.description;
                    //     vm.form.playbook_id = playbook.id;

                    //     if (vm.step < 2) {
                    //         vm.step++;
                    //     }
                    // }
                });
            } else {
                if (vm.step < 2) {
                    vm.step++;
                }
            }
        },

        handlePrevStep () {
            const vm = this;

            if (vm.step > 0) {
                vm.step--;
            }
        },

        handleNextStep () {
            const vm = this;

            vm.step++;
        },

        fileSelected (event) {
            const vm = this;
            const canvaFrom = vm.$refs['canva-playbook-form'];

            vm.file = event.target.files[0];

            let formData = new FormData();
            formData.append('file', vm.file);

            vm.uploadPlaybookCanvaZip({formData, setFieldError: canvaFrom.setFieldError}).then((result) => {
                if (result) {
                    vm.form.title   = vm.canvaZipFiles.name;
                    vm.form.batch   = vm.canvaZipFiles.batch;
                    vm.canvaFiles   = JSON.parse(JSON.stringify(vm.canvaZipFiles.steps));
                    vm.step++;
                }
            });
        },

        toggleOption (ev, slotIndex) {
            const vm = this;

            vm.selectedSlot = slotIndex;
            let elm = ev.currentTarget;
            let targetElm = elm.querySelector('.dropdown_wpr');

            if (targetElm.classList.contains('active')) {
                targetElm.classList.remove('active');
            } else {
                vm.closeAllToggleOption();
                targetElm.classList.add('active');
            }
        },

        closeAllToggleOption () {
            let allTargetElm = document.querySelectorAll('.book_item .options .dropdown_wpr');

            allTargetElm.forEach(function (item) {
                if (item.classList.contains('active')) {
                    item.classList.remove('active');
                }
            });
        },

        renameTitle (ev, tarClass, step) {
            const vm = this;
            const elm = ev.currentTarget.closest(`.${tarClass}`);

            if (elm.classList.contains('editable')) {
                elm.classList.remove('editable');

                if (step && step.id) {
                    if (tarClass == 'sl_no') {
                        vm.renamePlaybookCanvaStep(step);
                    } else {
                        vm.updatePlaybookCanvaStepTitle(step);
                    }
                }
            } else {
                elm.classList.add('editable');
            }
        },

        addNewSlot (order) {
            const vm = this;

            vm.createPlaybookCanvaBlankStep({ batch: vm.form.batch , playbook_id: vm.form.playbook_id, order }).then((result) => {
                if (result) {
                    vm.getPlaybookCanvaSteps({ playbook_id: vm.form.playbook_id, batch: vm.form.batch }).then((result) => {
                        if (result) {
                            vm.canvaFiles = JSON.parse(JSON.stringify(vm.canvaZipFiles.steps));
                        }
                    });
                }
            });
        },

        handleDelete (id, group) {
            const vm = this;

            vm.archivePlaybookCanvaSteps({ id, group }).then((result) => {
                if (result) {
                    vm.getPlaybookCanvaSteps({ playbook_id: vm.form.playbook_id, batch: vm.form.batch }).then((result) => {
                        if (result) {
                            vm.canvaFiles = JSON.parse(JSON.stringify(vm.canvaZipFiles.steps));
                        }
                    });
                }
            });
        },

        handleDegroup (stepId) {
            const vm = this;

            vm.degroupPlaybookCanvaStep(stepId).then((result) => {
                if (result) {
                    vm.getPlaybookCanvaSteps({ playbook_id: vm.form.playbook_id, batch: vm.form.batch }).then((result) => {
                        if (result) {
                            vm.canvaFiles = JSON.parse(JSON.stringify(vm.canvaZipFiles.steps));
                        }
                    });
                }
            });
        },

        handleRestore (step) {
            const vm = this;

            vm.restorePlaybookCanvaStep(step).then((result) => {
                if (result) {
                    vm.getArchivedPlaybookCanvaSteps({ playbook_id: vm.form.playbook_id, batch: vm.form.batch });

                    vm.getPlaybookCanvaSteps({ playbook_id: vm.form.playbook_id, batch: vm.form.batch }).then((result) => {
                        if (result) {
                            vm.canvaFiles = JSON.parse(JSON.stringify(vm.canvaZipFiles.steps));
                        }
                    });
                }
            });
        },

        handleSlotMove (evt) {
            const vm = this;

            let elmTarget = document.querySelector(`.canva_list li.slot-${evt.draggedContext.futureIndex}`);

            vm.handleSortSteps(true);

            if (evt.relatedRect.left > evt.draggedRect.left) {
                elmTarget.classList.add('wall_border_right');
            } else {
                elmTarget.classList.add('wall_border_left');
            }
        },

        handleItemMove (evt) {
            const vm = this;

            let elmTarget = evt.to;
            let elm = elmTarget.closest('li');

            vm.handleSortSteps(true);

            if (elm) {
                if (evt.relatedRect.left > evt.draggedRect.left) {
                    elm.classList.add('wall_border_right');
                } else {
                    elm.classList.add('wall_border_left');
                }
            }
        },

        handleSortSteps (move = false) {
            const vm = this;
            const alElm = document.querySelectorAll('.canva_list li');

            alElm.forEach(item => {
                if (item.classList.contains('wall_border_right')) {
                    item.classList.remove('wall_border_right');
                }

                if (item.classList.contains('wall_border_left')) {
                    item.classList.remove('wall_border_left');
                }
            });

            if (move !== true) {
                vm.sortCanvaPlaybookSteps({ steps: vm.canvaFiles }).then((result) => {
                    if (result) {
                        vm.getPlaybookCanvaSteps({ playbook_id: vm.form.playbook_id, batch: vm.form.batch }).then((result) => {
                            if (result) {
                                vm.canvaFiles = JSON.parse(JSON.stringify(vm.canvaZipFiles.steps));
                            }
                        });
                    }
                });
            }
        },

        toggleExtract (ev, extraElm = '') {
            const elm = ev.currentTarget.closest('.book_item');

            if (elm.classList.contains('extracted')) {
                elm.classList.remove('extracted');
            } else {
                elm.classList.add('extracted');
            }

            if (extraElm) {
                const elm2 = ev.currentTarget.closest('.'+extraElm);

                if (elm2.classList.contains('extracted')) {
                    elm2.classList.remove('extracted');
                } else {
                    elm2.classList.add('extracted');
                }
            }
        },

        handleArrange (item, itemIndex, type) {
            const vm = this;
            let moveFrom = itemIndex;

            vm.canvaFiles[vm.selectedSlot].items.splice(moveFrom, 1);

            if (type === 'neg') {
                vm.canvaFiles[vm.selectedSlot].items.splice((moveFrom - 1), 0, item);
            }

            if (type === 'pos') {
                vm.canvaFiles[vm.selectedSlot].items.splice((moveFrom + 1), 0, item);
            }

            vm.sortCanvaPlaybookSteps({ steps: vm.canvaFiles }).then((result) => {
                if (result) {
                    vm.getPlaybookCanvaSteps({ playbook_id: vm.form.playbook_id, batch: vm.form.batch }).then((result) => {
                        if (result) {
                            vm.canvaFiles = JSON.parse(JSON.stringify(vm.canvaZipFiles.steps));
                        }
                    });
                }
            });
        },

        closeDrawer () {
            const vm = this;

            vm.slotDetails = false;
            vm.showArchive = false;
        },

        closeTutorialListDropdown(){
            const vm = this;

            vm.tutorialList = false;
        },

        handleTutorialVideo () {
            const vm = this;

            vm.tutorialVideo = 'https://www.loom.com/embed/8fc1639777384a86abe7665af553b565?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true';

            vm.tutorial = true;
        },

        handleBuildPlaybook () {
            const vm = this;
            const canvaFrom = vm.$refs['canva-playbook-form'];
            const emptySteps = vm.canvaFiles.filter(file => file.items.length == 0).length;

            if (emptySteps) {
                canvaFrom.validate().then((form) => {
                    if (form.valid) {
                        vm.emptyStepConfirmationPrompt = true;
                    } else {
                        if (form && Object.keys(form.errors).length) {
                            Toastr.error(Object.values(form.errors)[0]);
                        }

                        const elements = document.getElementsByClassName('has-error');

                        if (elements.length) {
                            elements[0].scrollIntoView(true);
                        }
                    }
                });
            } else {
                vm.buildPlaybook();
            }

        },

        buildPlaybook () {
            const vm = this;
            const canvaFrom = vm.$refs['canva-playbook-form'];

            canvaFrom.validate().then((form) => {
                if (form.valid) {
                    vm.buildPlaybookFromCanva(vm.form).then((result) => {
                        if (result) {
                            vm.emptyStepConfirmationPrompt = false;
                            vm.closeModal();
                        }
                    });
                } else {
                    vm.emptyStepConfirmationPrompt = false;

                    if (form && Object.keys(form.errors).length) {
                        Toastr.error(Object.values(form.errors)[0]);
                    }

                    const elements = document.getElementsByClassName('has-error');

                    if (elements.length) {
                        elements[0].scrollIntoView(true);
                    }
                }
            });
        },

        handleDeleteConfirmation (step) {
            const vm = this;

            vm.deleteCanvaStepId        = step;
            vm.deleteConfirmationPrompt = true;
        },

        handleForceDeleteCanvaStep (id) {
            const vm = this;

            vm.forceDeleteLoaderStatus = true;

            vm.deletePlaybookCanvaStep(id).then((result) => {
                if (result) {
                    vm.deleteConfirmationPrompt = false;
                    vm.getArchivedPlaybookCanvaSteps({ playbook_id: vm.form.playbook_id, batch: vm.form.batch });
                }

                vm.forceDeleteLoaderStatus = false;
            });
        },

        handleCreateCategory () {
            const vm = this;

            const categoryForm = vm.$refs['category-form'];

            categoryForm.validate().then((result) => {
                if (result.valid) {
                    vm.categoryForm.setFieldError = categoryForm.setFieldError;

                    vm.createPlaybookCategory(vm.categoryForm).then((result) => {
                        if (result) {
                            vm.createCategory    = false;
                            vm.categoryForm.name = '';
                            vm.getPlaybookCategories();
                        }
                    });
                }
            });
        },
    }
}
</script>

<style scoped>
.canva_playbook_form {
    display: flex;
    flex-direction: column;
    height: inherit;
}

.global_setting .tabs_content .content_area {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    padding: 30px;
}

.info_bar {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    margin: 0 auto;
    position: relative;
}

.info_bar .btn_wpr {
    position: absolute;
    left: 0;
    top: 25px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.info_bar .tutorial_btn{
    display: flex;
    align-items: center;
    position: relative;
}
.info_bar .tutorial_btn label {
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    color: #2f7eed;
    cursor: pointer;
    min-width: auto;
}
.info_bar .tutorial_btn img {
    max-height: 18px;
    width: auto;
    margin-left: 5px;
    cursor: pointer;
}
.info_bar .tutorial_btn .dropdown_wpr{
    min-width: max-content;
}
.info_bar .tutorial_btn .dropdown_wpr:before{
    display: none;
}
.info_bar .tutorial_btn .dropdown_wpr ul li{
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    color: #5a5a5a;
}

.info_bar .action_btn {
    padding: 4px 10px;
    background: #fff;
    border-radius: 11px;
    font-size: 11px;
    line-height: 14px;
    color: #2f7eed;
    font-weight: 500;
    cursor: pointer;
}

.step_bar2 {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding: 20px 30px 10px 30px;
}

.step_bar2 ul {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 25px;
    position: relative;
    z-index: 1;
}

.step_bar2 ul li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.step_bar2 ul li h6 {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #121525;
    white-space: nowrap;
    text-align: center;
}

.step_bar2 ul li span {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #fff;
    border: 3px solid #e5e5e5;
    font-size: 12px;
    font-weight: 500;
    color: #2c3e50;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.step_bar2 .total_bar {
    position: absolute;
    content: '';
    top: 32px;
    left: 30px;
    right: 30px;
    height: 2px;
    background: #d9d9d9;
}

.step_bar2 .total_bar span {
    background: #a1c8ff;
    position: absolute;
    left: 0;
    height: 100%;
    transition: all 0.3s ease-in-out;
}

.step_bar2 ul li.active span {
    background: #2f7eed;
    color: #fff;
    border-color: #a1c8ff;
}

.wrapper_box {
    max-width: 800px;
    width: 100%;
    margin: 30px auto;
    padding: 15px 30px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
}

.wrapper_box.draggable_box {
    max-width: 100%;
}

.note {
    border-top: 1px solid #e9e9e9;
    padding: 15px 30px 10px 30px;
    margin: 15px -30px 0 -30px;
    background: #fff;
    text-align: center;
}

.note h4 {
    font-size: 13px;
    line-height: 22px;
    color: #575757;
    font-weight: 400;
    max-width: 600px;
    margin: 0 auto;
}

.upload_image :deep(.upload_area) {
    background: #f9f9f9;
}

.upload_zip label {
    background: #fff;
    border-radius: 10px;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.upload_zip label img.icon {
    height: 50px;
    width: auto;
    opacity: 0.5;
    transform: none;
}

.upload_zip h4 {
    font-size: 16px;
    line-height: 22px;
    color: #575757;
    font-weight: 400;
    margin: 20px 0 0;
    text-align: center;
}

.upload_zip h4 img {
    max-height: 18px;
    width: auto;
    margin-bottom: -2px;
    padding: 0 5px;
}

.canva_list {
    display: flex;
    flex-wrap: wrap;
    margin: 15px 0;
}

.canva_list li {
    width: 25%;
    display: flex;
    flex-direction: column;
    padding: 15px;
    line-height: 0;
    position: relative;
}

.canva_list li.wall_border_right:after {
    border-right: 2px solid #9cc5ff;
    position: absolute;
    content: '';
    right: 0;
    top: 40px;
    bottom: 20px;
}

.canva_list li.wall_border_left:after {
    border-right: 2px solid #9cc5ff;
    position: absolute;
    content: '';
    left: 0;
    top: 40px;
    bottom: 20px;
}

.canva_list li .sl_no {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 10px;
    font-size: 15px;
    line-height: 18px;
    font-weight: 400;
    color: #5a5a5a;
    text-align: center;
    position: relative;
    z-index: 1;
}

.canva_list li .sl_no label {
    padding-right: 5px;
}

.canva_list li .sl_no h4 {
    font-size: 15px;
    line-height: 18px;
    font-weight: 400;
    color: #5a5a5a;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.canva_list li .add_slot {
    position: absolute;
    right: -9px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    line-height: 20px;
    color: #5a5a5a;
    border-radius: 50%;
    background: #e9e9e9;
    border: 1px solid #e9e9e9;
    cursor: pointer;
    z-index: 4;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.canva_list li:hover .add_slot {
    opacity: 1;
}

.canva_list .slot_wpr {
    width: 100%;
    border-radius: 5px;
}

.canva_list .slot_wpr .slot_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.slot_wpr .slot_head .handle_slot {
    cursor: move;
    font-size: 12px;
    color: #5a5a5a;
}

.slot_wpr .slot_head span {
    padding: 2px 7px;
    background: #f5f5f5;
    border-radius: 10px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #5a5a5a;
    cursor: pointer;
}

.canva_list .slot_wpr .slot_head .delete_btn {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.canva_list .slot_wpr .slot_head .delete_btn img {
    max-height: 12px;
    width: auto;
}

.canva_list li:hover .slot_wpr .slot_head .delete_btn {
    opacity: 1;
}

.canva_list .slot_wpr .base_size {
    position: relative;
    width: 100%;
    padding-bottom: 129%;
    margin-top: 10px;
    overflow-y: auto;
    border-radius: 6px;
    z-index: 2;
}

.canva_list .slot_wpr .base_size::-webkit-scrollbar {
    display: none;
    width: 3px;
}

.canva_list .slot_wpr .base_size::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #d9d9d9;
}

.canva_list li:hover .slot_wpr .base_size::-webkit-scrollbar {
    display: block;
}

.slot_wpr .slot_items {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.slot_wpr .slot_items.blank {
    background: #f5f5f5;
}

.slot_items .book_item {
    /* background: #fff; */
    position: relative;
}

.slot_items .book_item:not(:last-of-type) {
    padding-bottom: 10px;
}

.book_item.sortable-chosen {
    background: #f5f5f5;
    border-radius: 6px;
}

.slot_items .book_item .page_title {
    position: absolute;
    top: 40px;
    left: 15px;
    right: 15px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.slot_items .book_item:hover .page_title,
.slot_items .book_item.editable .page_title {
    opacity: 1;
}

.slot_items .book_item h4 {
    padding: 7px 15px;
    display: flex;
    align-items: center;
    background: #fff;
    border: 1px solid #a0c8ff;
    position: relative;
}

.slot_items .book_item .page_title h4 {
    border-radius: 15px;
    cursor: pointer;
}

.slot_items .book_item h4 .title {
    margin-right: 5px;
}

.slot_items .book_item h4 .title[data-v-tooltip]:hover:after {
    width: 100%;
    font-size: 12px;
    line-height: 16px;
}

.slot_items .book_item h4 label {
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: #121525;
    margin-right: auto;
    cursor: pointer;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.slot_items .book_item .options {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 24px;
    height: 14px;
    background: #fff;
    border: 1px solid #a0c8ff;
    flex-shrink: 0;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #5a5a5a;
    margin-left: auto;
    cursor: pointer;
    z-index: 2;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.canva_list li:hover .slot_items .book_item .options {
    opacity: 1;
}

.canva_list li .rename_fld {
    background: #fff;
    border: 1px solid #a0c8ff;
    padding: 8px 45px 8px 10px;
    border-radius: 3px;
    position: relative;
    flex-grow: 1;
    display: none;
}

.canva_list li .rename_fld :deep(input) {
    width: 100%;
    font-size: 13px;
    line-height: 16px;
}

.canva_list li .rename_fld .save_btn {
    padding: 3px 5px;
    background: #2f7eed;
    color: #fff;
    border-radius: 3px;
    font-size: 8px;
    line-height: 10px;
    text-transform: uppercase;
    cursor: pointer;
    text-wrap: nowrap;
    position: absolute;
    right: 8px;
    top: 8px;
}

.canva_list li .slot_items .book_item.editable .rename_fld,
.canva_list li .sl_no.editable .rename_fld {
    display: block;
}

.canva_list li .slot_items .book_item.editable h4,
.canva_list li .sl_no.editable h4 {
    display: none;
}

.slot_items .book_item .options .dropdown_wpr {
    left: auto;
    right: 0;
}

.slot_items .book_item .options .dropdown_wpr:before {
    display: none;
}

.slot_items .book_item .options .dropdown_wpr ul li {
    min-width: 100px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #121525;
    padding: 3px 10px;
}

.slot_items .book_item .page_wpr {
    padding: 3px;
    background: #fff;
    border-radius: 6px;
    border: 0;
    position: relative;
}

.slot_items .book_item .page_wpr:after,
.slot_items .book_item.active .page_wpr:after {
    position: absolute;
    content: '';
    left: 3px;
    right: 3px;
    bottom: 3px;
    top: 3px;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: all 0.3s ease-in-out;
    cursor: move;
}

.slot_items .book_item:hover .page_wpr:after,
.slot_items .book_item.editable .page_wpr:after,
.slot_items .book_item:hover .page_wpr:after,
.slot_items .book_item.active .page_wpr:after {
    opacity: 1;
}

.slot_items .book_item img {
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    cursor: move;
}

.canva_list li.active .slot_items .book_item .page_wpr,
.canva_list li .slot_items .book_item.active .page_wpr {
    border: 2px solid #9cc5ff;
}

.canva_list li.active .sl_no:before {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    top: -6px;
    border-radius: 0 0 6px 6px;
    background: #eff5ff;
    z-index: -1;
}

.slot_modal .canva_list li {
    width: 100%;
}

.slot_modal .book_item .page_wpr img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: 0 0;
    transition: all 0.3s ease-in-out;
}

.slot_modal .book_item.extracted .page_wpr img {
    height: auto;
}

.slot_modal .book_item .expand_btn {
    position: absolute;
    left: 15px;
    top: 10px;
    width: 24px;
    height: 24px;
    background: #fff;
    border: 1px solid #a0c8ff;
    flex-shrink: 0;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5a5a5a;
    cursor: pointer;
    z-index: 2;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.slot_modal .book_item .expand_btn img {
    max-height: 14px;
    width: auto;
    filter: grayscale(1);
    border-radius: 0;
    cursor: pointer;
}

.canva_list li .slot_items .book_item:hover .expand_btn {
    opacity: 1;
}

.slot_modal .book_item .rearrange {
    position: absolute;
    left: 15px;
    bottom: 25px;
    overflow: hidden;
    background: #fff;
    border: 1px solid #a0c8ff;
    padding: 3px 0;
    flex-shrink: 0;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
    opacity: 1;
    transition: all 0.3s ease-in-out;
}

.slot_modal .book_item .rearrange span {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    color: #999;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.slot_modal .book_item .rearrange span i {
    pointer-events: none;
}

.slot_modal .book_item .rearrange span.disabled {
    pointer-events: none;
}

.slot_modal .book_item .rearrange span:hover {
    color: #121525;
}

/*------------ archive drawer ----------------- */
.archive_drawer .slot_items {
    max-height: 260px;
    overflow-y: scroll;
}

.archive_drawer .slot_items.extracted {
    max-height: none;
}

.archive_drawer .slot_items::-webkit-scrollbar {
    width: 3px;
}

.archive_drawer .slot_items::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #d9d9d9;
}

.slot_modal .slot_head span {
    position: absolute;
    left: 50%;
    top: 25px;
    transform: translateX(-50%);
    padding: 2px 7px;
    background: #f5f5f5;
    border-radius: 10px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #5a5a5a;
    cursor: pointer;
    z-index: 2;
}

.slot_modal .slot_head .options {
    position: absolute;
    right: 30px;
    top: 30px;
    width: 24px;
    height: 14px;
    background: #fff;
    flex-shrink: 0;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #5a5a5a;
    margin-left: auto;
    cursor: pointer;
    z-index: 2;
    transition: all 0.3s ease-in-out;
}

.slot_modal .slot_head .options .dropdown_wpr {
    left: auto;
    right: 0;
}

.slot_modal .slot_head .options .dropdown_wpr:before {
    display: none;
}

.slot_modal .slot_head .options .dropdown_wpr ul li {
    min-width: 100px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #121525;
    padding: 3px 10px;
}

.archive_drawer .slot_items .book_item .page_wpr {
    padding: 0;
}

.archive_drawer .slot_items .book_item .page_wpr:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.empty_box {
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background: #fff;
    border-radius: 5px;
}

.empty_box img {
    max-height: 70px;
    width: auto;
    margin-bottom: 20px;
}

.empty_box h4 {
    font-size: 15px;
    line-height: 22px;
    font-weight: 400;
    color: #2c3e50;
}

.modal.prompt h5 {
    max-width: 425px;
}

.modal.prompt .logo_img {
    max-height: 90px;
    width: auto;
    margin-bottom: 30px;
}

.action_wpr .btn {
    text-decoration: none;
    line-height: 34px;
}

.canva_list_loader {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
}

.file-upload-loader {
    background: #fff;
    border-radius: 10px;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.file-upload-loader h4 {
    font-size: 16px;
    line-height: 22px;
    color: #575757;
    font-weight: 400;
    margin: 20px 0 0;
    text-align: center;
}


@media(max-width: 991px) {
    .canva_list li {
        width: 33.333%;
    }
}

@media(max-width: 768px) {
    .canva_list li {
        width: 50%;
    }

    .info_bar .btn_wpr {
        top: 100%;
    }
}

@media(max-width: 499px) {
    .canva_list {
        margin-top: 0;
    }

    .canva_list li {
        width: 100%;
        padding: 15px 5px;
    }

    .wrapper_box {
        padding: 15px 20px;
    }

    .info_bar .tutorial_btn .dropdown_wpr{
        width: 300px;
        min-width: auto;
    }
}
</style>